.services-background-image img {
  width: 8rem;
  height: 8rem;
}
.service-background-option img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

video:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

img:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.ant-table-cell > th {
  padding-right: 16px;
}
