/* Modal Header */
.ant-modal-title {
    font-weight: bold;
    font-size: 1.2rem;
    color: #333;
}

.ant-modal-header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e8e8e8;
    padding: 16px;
}

/* Modal Close Button */
.ant-modal-close {
    top: 16px;
    right: 16px;
}

/* List inside the modal */
.modal-content-list {
    padding: 16px;
}

.modal-content-list ul {
    padding-left: 20px;
}

.modal-content-list li {
    margin-bottom: 8px;
    font-size: 1.5rem;
    color: #555;
    list-style-type: disc;
}

/* Close Button */
.modal-close-button {
    margin-top: 20px;
    width: 100%;
    background-color: #1890ff;
    color: white;
    border: none;
    font-weight: bold;
}
.modal-close-button:hover {
    background-color: #40a9ff;
}

.modal-title {
    font-size: 30px !important; /* Adjust size as needed */
    font-weight: bold !important; /* Optional */
  }
  
