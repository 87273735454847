.add-product-modal-main-container-concert {
  width: 80%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  z-index: 100;
  overflow-y: auto;
  padding-top: 2rem;
}

.add-product-modal-container-concert {
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
  width: 50rem;
  background-color: #fff;
  border-radius: 2.5rem;
  margin-bottom: 10rem;
  margin-top: 0;
  height: auto;
  overflow-y: auto;
}

.add-product-modal-input-title h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000;
}

.add-product-modal-amount-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.add-product-modal-image img {
  width: 10rem;
  height: 10rem;
  cursor: pointer;
}

.modal-btn-container {
  display: flex;
  flex-direction: row;
}

.loader {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
