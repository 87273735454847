.admin-panel-login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-main-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60%;
  margin-top: 20%;
}
